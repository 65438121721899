import React, { useRef, useEffect, useState, useCallback } from "react";

import { normalizeUrl } from "@utils/url";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { getJwt, useAuth } from "@api/auth";
import { useViewContext } from "~/state";

type BubbleMessages =
	| {
			type: "dealerid";
			data: number;
	  }
	| {
			type: "user";
			data: string;
	  }
	| {
			type: "token";
			data: string | null;
	  }
	| {
			type: "endpoint";
			data: string;
	  }
	| {
			type: "cmd";
			message: "ready";
	  }
	| {
			type: "cmd";
			message: "show_detail";
			data: string;
	  }
	| {
			type: "cmd";
			message: "detail_ready";
	  };

const targetRxOrigin = process.env.GATSBY_AVATRADE_DOMAIN!;
const targetRxPath =
	process.env.NODE_ENV !== "development" ? "" : "/version-test";
const iFrameSrc = normalizeUrl(targetRxOrigin, targetRxPath, "/dp_offers_tab");
const targetOrigin = process.env.NODE_ENV !== "development" ? iFrameSrc : "*";
const endpoint = process.env.GATSBY_API_URL;

const useStyles = makeStyles(() => ({
	contentContainer: {
		display: "flex",
		flexFlow: "row nowrap",
		height: "100%",
		overflow: "hidden",
		margin: 0,
		padding: 0,
	},
	detailsContainer: {
		padding: 0,
		border: "none",
		width: 480,
		height: 1230,
	},
	iFrame: {
		height: "100%",
		width: "100%",
		border: "none",
	},
	infoContainer: {
		margin: "0 auto",
		padding: "5% 0",
		display: "flex",
		flexFlow: "column nowrap",
		justifyContent: "center",
		alignItems: "center",
		maxWidth: 980,
		height: "100%",
		textAlign: "center",
	},
}));

interface VehicleDetailsProps {
	src: string;
}

const VehicleDetails: React.FC<VehicleDetailsProps> = ({ src }) => {
	const [loading, setLoading] = useState(true);
	const iframeEl = useRef<HTMLIFrameElement>(null);

	const styles = useStyles();

	useEffect(() => {
		const rxHandler = (ev: MessageEvent<BubbleMessages>) => {
			const isValidSource =
				ev.origin === targetRxOrigin &&
				ev.source === iframeEl.current?.contentWindow;
			if (!isValidSource) return;

			if (ev.data.type === "cmd" && ev.data.message === "detail_ready") {
				setLoading(false);
			}
		};
		window.addEventListener("message", rxHandler);

		return () => window.removeEventListener("message", rxHandler);
	}, []);

	return (
		<>
			{loading && <LinearProgress />}
			<Box className={styles.detailsContainer}>
				<iframe
					id="ava-trade-pro-offers-details"
					ref={iframeEl}
					className={styles.iFrame}
					src={src}
					title="AVA Trade Pro Offers View"
				/>
			</Box>
		</>
	);
};

export const OffersDisplay: React.FC = () => {
	const { user, selectedCompany } = useAuth();
	const [loading, setLoading] = useState(true);
	const iframeEl = useRef<HTMLIFrameElement>(null);
	const {
		actions: { openModal: showDetails, closeModal: closeDetails },
	} = useViewContext();
	const styles = useStyles();

	const showVehicleDetails = useCallback(
		(url: string) => {
			showDetails({
				content: <VehicleDetails src={url} />,
				props: {
					title: "Details",
					classes: { content: styles.detailsContainer },
				},
			});
		},
		[showDetails, styles.detailsContainer],
	);

	const postMessage = useCallback((message: BubbleMessages) => {
		if (iframeEl.current?.contentWindow) {
			iframeEl.current.contentWindow.postMessage(message, {
				targetOrigin,
			});
		}
	}, []);

	useEffect(() => {
		if (selectedCompany?.id && !loading) {
			postMessage({ type: "dealerid", data: selectedCompany.id });
		}
	}, [selectedCompany, loading, postMessage]);

	const postToken = useCallback(async () => {
		if (iframeEl.current?.contentWindow) {
			const creds = getJwt();
			postMessage({ type: "token", data: creds });
			postMessage({ type: "endpoint", data: endpoint });
			postMessage({ type: "user", data: JSON.stringify(user) });

			if (selectedCompany?.id && !loading) {
				postMessage({ type: "dealerid", data: selectedCompany.id });
			}
			setLoading(false);
		}
	}, [loading, selectedCompany, user, postMessage]);

	useEffect(() => {
		const rxHandler = (ev: MessageEvent<BubbleMessages>) => {
			const isValidSource =
				ev.origin === targetRxOrigin &&
				ev.source === iframeEl.current?.contentWindow;
			if (!isValidSource) return;

			if (ev.data.type === "cmd" && ev.data.message === "ready") {
				postToken();
			}

			if (ev.data.type === "cmd" && ev.data.message === "show_detail") {
				const url = ev.data.data;
				showVehicleDetails(url);
			}
		};
		window.addEventListener("message", rxHandler);

		return () => window.removeEventListener("message", rxHandler);
	}, [postToken, showVehicleDetails]);

	return (
		<Box
			display="flex"
			flexDirection="column"
			flexWrap="nowrap"
			height="100%"
		>
			{loading && <LinearProgress />}
			<Box className={styles.contentContainer}>
				<iframe
					id="ava-trade-pro-offers"
					ref={iframeEl}
					className={styles.iFrame}
					src={iFrameSrc}
					title="AVA Trade Pro Offers View"
				/>
			</Box>
		</Box>
	);
};
